//トップページ　キービジュアルスライダー
import slick from 'slick-carousel';
import anime from 'animejs/lib/anime.es.js';

export function menuFunc() {

  let indexPage = $('#menuTop').length;

  if (indexPage) {

    $('.keyVisualSlider').slick({
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 4000,
      arrows: false,
      fade: true,
    });
  }
}
//トップページ　キービジュアルスライダー
import slick from 'slick-carousel';
import anime from 'animejs/lib/anime.es.js';

export function idxFunc() {

  let indexPage = $('#indexTop').length;

  if (indexPage) {
    let resizeWidth = $('#responsibleCheck').width();

    if(resizeWidth == 1) {
      $("#playBtn").click(function(){
        if($(this).hasClass("soundOff")) {
          $(this).removeClass("soundOff");
          $(this).addClass("soundOn");
          $("video").prop('muted', false);
      } else {
          $(this).removeClass("soundOn");
          $(this).addClass("soundOff");
          $("video").prop('muted', true);
      }
      });
    }
  }
}
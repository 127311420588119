import PerfectScrollbar from 'perfect-scrollbar';

export function headerFunc() {

  let scrollPosition;
  $(".hamburgerBtn").click(function(){
    $("#header").toggleClass("open");
    if($("#header").hasClass("open")){
      if($("video").prop('muted', false)){
        $("video").prop('muted', true);
      }
      scrollPosition = $(window).scrollTop();
      $('body').addClass('fixedBody').css({
        'top': -scrollPosition
      });
    }else{
      if($("#playBtn").hasClass("sound_on")){
        $("video").prop('muted', false);
      }
      $('body').removeClass('fixedBody').css({
        'top': 0
      });
      window.scrollTo(0, scrollPosition);
    }
  });

}

export function headerResizeFunc() {
  // var ps = new PerfectScrollbar('.headerNavArea');
  //var flag = '';
  // 画面サイズの判定
  // function windowSize() {
  //     var w = $(window).innerWidth();
  //     if (w <= 767 && flag != 'sp') {
  //         flag = 'sp';
  //         var ps = new PerfectScrollbar('.headerNavArea');
  //     } else if (w > 767 && flag != 'pc') {
  //         flag = 'pc';
  //         var ps = new PerfectScrollbar('.headerNavArea');
  //         ps.destroy();
  //         ps = null;
  //     }
  // }

  //windowSize();
  // $(window).on('load resize', function() {
  //   windowSize();
  // });
}

export function headerScrFunc() {
  // let headerPage = $('#header').length;

  // if (headerPage) {
  //   const header = document.querySelector('#header');

  //   const callback = (function (changes) {
  //     for (let change of changes) {
  //       if (change.isIntersecting) {
  //         header.classList.remove('scrollDown');
  //       } else {
  //         header.classList.add('scrollDown');
  //       }
  //     }
  //   });

  //   const observer = new IntersectionObserver(callback);

  //   const target = document.querySelector('#mainview');
  //   observer.observe(target);
  // }
}